import { mapGetters } from 'vuex'

/* eslint-disable */
export const memberMessagePopupMixin = {
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      alertMessage: '',
      alertVariant: 'warning'
    }
  },
  methods: {
    async loadPopup() {
      await this.loadMemberPopup(this.userId).then(() => {
        if (!!this.memberPopup.pop_message) {
          this.$root.$emit('bv::show::modal', 'memberPopupModal')
          // flag as shown once for this session
          this.setMemberPopupShown(true)
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      memberPopup: 'membership/memberPopup'
    })
  }
}

export default memberMessagePopupMixin
