<template>
    <span class="search">
      <router-link to="/search/search-site">
        <img src="../../assets/svgs/icon-search.svg" alt="Search" height="24" width="24" />
      </router-link>
    </span>
</template>

<script>
export default {
  name: 'header-search'
}
</script>

<style scoped lang="scss">
@import '@/styles/settings.scss';
.col {
  @include breakpoint(sm) {
    flex: 0 0 auto !important;
  }
}
.search {
  display: inline;
  height: 58px;
  width: 100%;
  align-items: center;
  justify-content: center;
 // border: 3px solid #fff;
  cursor: pointer;
  @include breakpoint(sm) {
    height: auto;
    padding: 10px 10px;
  }
  &:hover {
    border-color: $secondary-color;
  }
  img {
    @include breakpoint(sm) {
      height: 28px;
      width: 28px;
    }
  }
}
</style>
